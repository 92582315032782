import React from 'react'
import './project.css';
import {BsChevronRight} from 'react-icons/bs';
const Project = ({client, tech, info, link, bgImage}) => {
    return (
        <div className='jt__project'>
            <div className='jt__project-image'>
                <img src={bgImage} alt={client}/>
            </div>
            <div className="card">

                <div className="card-header">
                    <h3>{client}
                    </h3>
                    <h6>{tech}</h6>
                </div>
                <div className="card-body">
                    <p>{info}</p>
                </div>
                <div className="card-footer">
                    <a href={link} title={client} target="_blank" rel="noreferrer">
                        Visit Site</a><BsChevronRight/>
                </div>
            </div>

        </div>
    )
}

export default Project