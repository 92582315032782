import React from 'react';
import './skills.css';
import Skill from '../../components/skill/Skill';



const skill_data = [
    {
        title: "Frontend",
        list: "Html, CSS/SCSS, Javascript, JQuery,React, Typescript"
    }, {
        title: "Backend",
        list: "PHP, SQL"
    }, {
        title: "Design",
        list: "Design, Xd and Figma,Photoshop, Illustrator, Procreate"
    }, {
        title: "CMS",
        list: "WordPress, WPBakery, Elementor, Woocommerce, Joomla, Squarespace"
    }
]

const Skills = () => {
    return (
        <div className='jt__skills ' >
            
                <div className='jt__skills-container section__padding'>

                    <div className="jt__skills-grid">
                        {skill_data.map((data, index) => (<Skill  key={index} title={data.title} list={data.list}/>))}
                    </div>
                

            </div>

        </div>
    )
}

export default Skills