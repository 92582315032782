import React from 'react'
import {Parallax} from "react-parallax";
import { motion} from "framer-motion";
import side_stacks from '../../assets/svg/pattern.svg';
import './about.css';
const About = () => {

    const container = {
        hidden: { opacity: 0, x:100 },
        show: {
          opacity: 1,
          x:0,
          transition: {
            
            staggerChildren: 0.4
          }
        }
      }
      
      const item = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
      }

    return (
        <Parallax bgImage={side_stacks} strength={500} >
            <div className="jt__about " id="about">
                <div className='section__padding'>
                    <motion.div initial="hidden" animate="show" variants={container} className="jt__about-card">
                        <motion.h1 variants={item}>Jonathan Turnbull</motion.h1>
                        <motion.div variants={item} />
                        <motion.p variants={item}>Web Developer & Designer.</motion.p>
                        <motion.p variants={item}>Five years industry experience.</motion.p>
                        <motion.p variants={item}>Helping clients of all sizes to establish and enhance their online presence.</motion.p>
                            
                    </motion.div>
                </div>

            </div>
        </Parallax>
    )
}

export default About