import {React} from 'react'
import {Parallax} from "react-parallax";

import stacks from '../../assets/svg/pattern.svg';
import './circles.css';


const Circles = ({title}) => {
  
    return (
        <div className='jt__circles'>
            <Parallax className="jt__circles-circle" bgImage={stacks} strength={500}>

                <div className="section__padding " />
                <div className='jt__circles-title'>
                    <h1>{title}</h1>
                </div>
            </Parallax>
        </div>
    )
}

export default Circles