import './App.css';
import {React} from 'react';
import {
    Header,
    About,
    Skills,
    Design,
    Projects,
    Contact
} from './containers'
import Circles from './components/circles/Circles';
// import Waves from './components/waves/Waves';

import {motion} from 'framer-motion';

function App() {

  

    const section = {
        hidden: {
            opacity: 0,
            y: 200
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8
            }

        }
    }

    return (
        <div className="App">

            <Header/>

            <div className="jt__app-container jt__gradient-radial" >

                <About/>

                <motion.div
                    id="skills"
                    variants={section}
                    initial="hidden"
                    whileInView="show"
                    viewport={{
                    once: true
                }}>
                    <Circles title="Skills"/>

                    <Skills/>
                </motion.div>

                <motion.div
                    id="design"
                    variants={section}
                    initial="hidden"
                    whileInView="show"
                    viewport={{
                    once: true
                }}>
                    <Circles title="Designs"/>

                    <Design/>
                </motion.div>

                <motion.div
                    id="projects"
                    variants={section}
                    initial="hidden"
                    whileInView="show"
                    viewport={{
                    once: true
                }}>
                    <Circles title="Projects"/>
                    <Projects/>
                </motion.div>

                <motion.div variants={section}
                    initial="hidden"
                    whileInView="show"
                    viewport={{
                    once: true
                }}>
                 

                    <Contact/>
                </motion.div>

            </div>
          

        </div>
    );
}

export default App;
