import React from 'react'
import {Parallax} from 'react-parallax';
import side_stacks from '../../assets/svg/pattern.svg';
import './contact.css';
import { BsSend } from 'react-icons/bs';

const Contact = () => {
    return (
        <div className='jt__contact section' id="contact">
            <Parallax bgImage={side_stacks} strength={500}>
                <div className='jt__contact-heading section__padding'>
                    <div className='jt__contact-heading-container '>
                        <h1>Contact</h1>
                        <div className='jt__contact-seperator'/>
                        <p>If you would like to know more please send me a message.
                        </p>
                        <div className='jt__contact-link '>
                      
                            <a href="mailto:admin@jtportfolio.net">Send Message  <BsSend /></a>
                        </div>
                        
                        
                     
                    </div>
                </div>

            </Parallax>
        </div>
    )
}

export default Contact