import React from 'react'
import Navbar from '../../components/navbar/Navbar';
import './header.css';

const header = () => {
  return (
    <div className="jt__header">
       <Navbar />
       
    </div>
  )
}

export default header