import React, {useState} from 'react'
import './navbar.css';
import {BsGithub, BsInstagram} from 'react-icons/bs';
const open = {
    transform: 'translateX(0)'
}

const closed = {
    transform: 'translateX(100%)'
}

const Links = () => {
    return (
        <div className="header"> 
            <a className="jt__header-first-link" href="/#about">Jonathan Turnbull</a>
            <a href="/#about">About</a>
            <a href="/#skills">Skills</a>
            <a href="/#design">Design</a>
            <a href="/#projects">Projects</a>
            <a href="/#contact">Contact</a>
            <a href="https://github.com/pwolf888" target="_blank" rel="noreferrer">
                <BsGithub />
            </a>
            <a href="https://instagram.com/partywolf8" target="_blank" rel="noreferrer">
                <BsInstagram />
            </a>
        </div>
    )
}

const Navbar = () => {

    const [isNavOpen,
        setIsNavOpen] = useState(false);

    let openNav = (navOpen) => {

        return setIsNavOpen(navOpen);
    }

    return (
        <div className="jt__navbar">
            <div onClick={() => openNav(!isNavOpen)} className="hamburger">
                {isNavOpen
                    ? <svg
                            className="svg-lines"
                            viewBox="0 0 97 97"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 5.5L91.5 92M5 92L91.5 5.5" stroke="#172A3A" strokeWidth="20"/>
                        </svg>
                    : <svg
                        className="svg-lines"
                        viewBox="0 0 63 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            id="lines"
                            d="M0 7H62.5M0 29.5H62.5M0 51H62.5"
                            stroke="#172A3A"
                            strokeWidth="13"/>
                    </svg>
                }
            </div>
            <nav className="jt__navbar-nav">

                <Links/>
            </nav>
            <nav
                onClick={() => openNav(!isNavOpen)}
                className="jt__navbar-nav-mob"
                style={isNavOpen ? open : closed}>

                <Links/>
            </nav>

        </div>
    )
}

export default Navbar