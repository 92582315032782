import React from 'react'
import './skill.css';
const Skill = ({title, list}) => {
  return (
    <div className='jt__skill'>
        <div className='jt__skill-container'>
            <h1>{title}</h1>
            <div />
            <p>{list}</p>
        </div>
    </div>
  )
}

export default Skill