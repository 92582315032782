import React from 'react'
import {
    book,
    cars,
    corporate,
    elegant,
    food,
    icecream,
    laptop,
    mountain,
    music,
    domain,
    space,
    garden,
    bookmob,
    carsmob,
    corporatemob,
    elegantmob,
    foodmob,
    icecreammob,
    laptopmob,
    mountainmob,
    musicmob,
    domainmob,
    spacemob,
    gardenmob
} from './import';
import Image from '../../components/image/Image'
import './design.css';  

const Design = () => {
    return (
        <div className="jt__designs section__padding">
          
            <div className="gallery gallery-desktop">
                <Image image={book} alt="cool design"/>
                <Image image={cars} alt="cool design"/>
                <Image image={corporate} alt="cool design"/>
                <Image image={elegant} alt="cool design"/>
                <Image image={food} alt="cool design"/>
                <Image image={icecream} alt="cool design"/>
                <Image image={laptop} alt="cool design"/>
                <Image image={mountain} alt="cool design"/>
                <Image image={music} alt="cool design"/>
                <Image image={domain} alt="cool design"/>
                <Image image={space} alt="cool design"/>
                <Image image={garden} alt="cool design"/>
            </div>

            <div className="gallery gallery-mob">
                <Image image={bookmob} alt="cool design"/>
                <Image image={carsmob} alt="cool design"/>
                <Image image={corporatemob} alt="cool design"/>
                <Image image={elegantmob} alt="cool design"/>
                <Image image={foodmob} alt="cool design"/>
                <Image image={icecreammob} alt="cool design"/>
                <Image image={laptopmob} alt="cool design"/>
                <Image image={mountainmob} alt="cool design"/>
                <Image image={musicmob} alt="cool design"/>
                <Image image={domainmob} alt="cool design"/>
                <Image image={spacemob} alt="cool design"/>
                <Image image={gardenmob} alt="cool design"/>
            </div>

        </div>
    )
}

export default Design