import React from 'react'
import "./projects.css";
import Project from '../../components/project/Project';
import {urbanspark,dentavision,brainlab,lyppard,brainworks} from "./import";


const projects = [
    {
        client: "Urban Spark Studio",
        tech: "Squarespace",
        info: "I had the opportunity to work on a project where I helped my client bring their " +
                "static brochure into the digital space. With a focus on showcasing their excepti" +
                "onal architectural work, I aimed to create an interactive and dynamic platform t" +
                "hat the client could easily maintain themselves. Throughout the process, I worke" +
                "d closely with the client's designer to ensure that the website aligned with the" +
                "ir vision.",
        link: "https://urbansparkstudio.com/",
        bgImage: urbanspark,
    }, {
        client: "Dentavision",
        tech: "WordPress, PHP, Elementor, Woocommerce",
        info: "I had the opportunity to gain valuable insights into the Woocommerce ecosystem a" +
                "nd develop expertise in managing large inventories of products. Additionally, I " +
                "successfully integrated our company's API with Woocommerce, ensuring seamless da" +
                "ta synchronisation between the two systems. To streamline the payment process, I" +
                " utilised the Westpac API to establish a robust payment system.",
        link: "https://dentavision.com.au/",
        bgImage: dentavision,
    }, {
        client: "Brain Lab 3",
        tech: "HTML, SCSS, JS, Bootstrap",
        info: "I designed an engaging website featuring fun cartoon characters from a comic boo" +
                "k to promote education. Using Adobe Xd, I created mock-ups from a clear client b" +
                "rief and brought them to life with CSS for an enjoyable user experience.",
        link: "https://brainlab3.com/",
        bgImage: brainlab,
    }, {
        client: "Lyppard",
        tech: "Joomla, PHP, CSS, JS",
        info: "I undertook the challenge of redesigning a veterinary supplies e-commerce websit" +
                "e, which involved creating mock-ups and implementing the chosen design using PHP" +
                " and web services to fetch data. Through this project, I was able to refine my p" +
                "roficiency in PHP, CSS, and Bootstrap, gaining valuable experience along the way" +
                ".",
        link: "https://lyppard.com.au/",
        bgImage: lyppard,
    }, {
        client: "Brainworks",
        tech: "Custom CMS, HTML, SCSS, JS, Bootstrap",
        info: "Converted designs into HTML for team's CMS integration, honing Bootstrap/SCSS ex" +
                "pertise. Collaborated with client on site layouts, contributing to successful fi" +
                "nal design.",
        link: "https://brainworks.com.au/",
        bgImage: brainworks,
    }
];

const Projects = () => {
    return (
        <div className='jt__projects section__padding' >
           

            <div className='jt__projects-list'>
                {projects.map((data, index) => (<Project
                    key={index}
                    client={data.client}
                    tech={data.tech}
                    info={data.info}
                    link={data.link}
                    bgImage={data.bgImage}/>))}
            </div>
        </div>
    )
}

export default Projects